import React from "react";
import ReactGA from "react-ga4"
import { Outlet, Link, Navigate, useLocation, useMatches } from "react-router-dom";
import { isLoggedIn } from "../auth";
import { getWorkoutName } from "../concept2";

export default function Root() {

  const location = useLocation()

  React.useEffect(() => {
    // Google Analytics
    console.log("ga send root")
    ReactGA.send("pageview");
  }, [location]);

  const matches = useMatches()

  var workoutSelected = null
  var workoutId = null
  for (const matchIndex in matches) {
    const match = matches[matchIndex]
    if (match.params['workoutId'] != null) {
      workoutId = match.params['workoutId']
      workoutSelected = getWorkoutName(parseInt(workoutId))
    }
  }

  if ( !isLoggedIn) {
    return (<div><Navigate to={`/welcome`}/></div>)
  } else {
    if (location.pathname === '/'){
      return (<div><Navigate to={`/user`}/></div>)
    } else {
      return (
        <>
          <div id="sidebar">
            <nav>
              <h1>Make Prergress!</h1>
              <ul>
                <li>
                  <Link to={`user`}>Profile</Link>
                </li>
                <li>
                  <Link to={`logout`}>Logout</Link>
                </li>
              </ul>
              <h2>Trends</h2>
              <ul>
                <li>
                  <Link to={`weeklysummary`}>Weekly Prergress</Link> 
                </li>
                <li>
                  <Link to={`cumulativedistance`}>Cumulative Distance</Link>
                </li>
                <li>
                  <Link to={`workoutsperweek`}>Workouts per Week</Link>
                </li>
                <li>
                  <Link to={`distanceperweek`}>Distance per Week</Link>
                </li>
                <li>
                  <Link to={`distancepermonth`}>Distance per Month</Link>
                </li>
                <li>
                  <Link to={`paceovertime`}>Avg Pace over Time</Link>
                </li>
                <li>
                  <Link to={`wattsoverheartrate`}>Watts vs. Heart Rate</Link>
                </li>
                <li>
                  <Link to={`cardioovertime`}>Cardio Efficiency over Time</Link>
                </li>
                <li>
                  <Link to={'paceoverdistance'}>Pace vs. Distance</Link>
                </li>
              </ul>
              <h2>Individual Workouts</h2>
              <ul>
                <li>
                  <Link to={'workoutlist'}>Select a Workout</Link>
                </li>
                { workoutSelected &&
                  <li><b>{workoutSelected}</b></li>
                }
                { workoutSelected &&
                  <li>
                    <Link to={`/details/${workoutId}/wattsduringworkout`}>Watts during Workout</Link>
                  </li>
                }
                { workoutSelected &&
                  <li>
                    <Link to={`/details/${workoutId}/wattsoverstrokerate`}>Watts vs. Stroke Rate</Link>
                  </li>
                }
                { workoutSelected &&
                  <li>
                    <Link to={`/details/${workoutId}/efficiencyduringworkout`}>Efficiency during Workout</Link>
                  </li>
                }
              </ul>
            
              
            </nav>
            
          </div>
          <div id="detail">
            <Outlet />
            <div id="about">
              <p>Developed by Dave, <a href="https://twitter.com/FraudulentTom">@FraudulentTom</a><br/>
              Feedback greatly appreciated!</p>
            </div>
          </div>
          
        </>
      );
    }
  }
}