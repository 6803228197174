import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import Root from "./routes/root";
import Welcome from "./routes/welcome";
import AuthReturn from "./routes/auth_return";
import Logout from "./routes/logout";
import User from "./routes/user";
import CumulativeDistance from "./routes/cumulativedistance";
import { loadAllWorkouts, loadUser, loadWorkoutDetails } from "./concept2";
import PaceOverTime from "./routes/paceovertime";
import WorkoutsPerWeek from "./routes/workoutsperweek";
import DistancePerWeek from "./routes/distanceperweek";
import WattsOverHeartRate from "./routes/wattsoverheartrate";
import PaceOverDistance from "./routes/paceoverdistance";
import WorkoutList from "./routes/workoutlist";
import WattsDuringWorkout from "./routes/wattsduringworkout";
import ReactGA from "react-ga4";
import CardioOverTime from "./routes/cardioovertime";
import WattsOverStrokeRate from "./routes/wattsoverstrokerate";
import EfficiencyDuringWorkout from "./routes/efficiencyduringworkout";
import WeeklySummary from "./routes/weeklysummary";
import DistancePerMonth from "./routes/distancepermonth";


ReactGA.initialize("G-LPFZZXGD9L");



const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    //errorElement: <ErrorPage />,
    children: [
      { path: "/user",
        element: <User />,
        loader: loadUser
      },
      { path: "/cumulativedistance",
      element: <CumulativeDistance />,
      loader: loadAllWorkouts
      },
      { path: "/paceovertime",
      element: <PaceOverTime />,
      loader: loadAllWorkouts
      },
      { path: "/workoutsperweek",
      element: <WorkoutsPerWeek />,
      loader: loadAllWorkouts
      },
      { path: "/distanceperweek",
      element: <DistancePerWeek />,
      loader: loadAllWorkouts
      },
      { path: "/distancepermonth",
        element: <DistancePerMonth />,
        loader: loadAllWorkouts
        },
      { path: "/wattsoverheartrate",
      element: <WattsOverHeartRate />,
      loader: loadAllWorkouts
      },
      { path: "/cardioovertime",
      element: <CardioOverTime />,
      loader: loadAllWorkouts
      },
      { path: "/paceoverdistance",
      element: <PaceOverDistance />,
      loader: loadAllWorkouts
      },
      { path: "/workoutlist",
      element: <WorkoutList/>,
      loader: loadAllWorkouts
      },
      {
        path: "/details/:workoutId/wattsduringworkout",
        element: <WattsDuringWorkout/>,
        loader: loadWorkoutDetails
      },
      {
        path: "/details/:workoutId/wattsoverstrokerate",
        element: <WattsOverStrokeRate/>,
        loader: loadWorkoutDetails
      },
      {
        path: "/details/:workoutId/efficiencyduringworkout",
        element: <EfficiencyDuringWorkout/>,
        loader: loadWorkoutDetails
      },
      {
        path: "/weeklysummary",
        element: <WeeklySummary/>,
        loader: loadAllWorkouts
      }
      
    ],
  },
  { path: "/welcome",
    element: <Welcome/>,
  },
  { path: "/return",
    element: <AuthReturn />,
  },
  { path: "/logout",
    element: <Logout/>
  },
 
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
