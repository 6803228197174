import { useLoaderData } from "react-router-dom";
import { vegaView, VEGA_CONFIG, VEGA_HEIGHT, VEGA_WIDTH } from "../vegahelpers";


export default function CumulativeDistance() {

    const data : any = useLoaderData()
    return vegaView(data, ((metadata) => { return {
        config: VEGA_CONFIG,
        width: VEGA_WIDTH,
        height: VEGA_HEIGHT,
        mark: 'area',
        title: {
            text: "Cumulative Meters Rowed",
        },
        transform: [{
            sort: [{"field": "date"}],
            "window": [{"op": "sum", "field": "total_distance", "as": "Total Distance (m)"}],
            "frame": [null, 0]
          }],
        encoding: {
            x: { field: 'date', type: 'temporal', axis: { title: "Date of Workout"}},
            y: { field: 'Total Distance (m)', type: 'quantitative' },
            tooltip: [
                {field: "date", type: "temporal"},
                {field: "total_distance", type: "quantitative"},
                {field: "Total Distance (m)", type: "quantitative"}
            ]
        },
        data: { name: 'table' }, 
    }}))
}