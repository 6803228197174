import { useLoaderData } from "react-router-dom";
import { vegaView, VEGA_CONFIG, VEGA_HEIGHT, VEGA_WIDTH } from "../vegahelpers";


export default function DistancePerWeek() {
    const data : any = useLoaderData()
    return vegaView(data, ((_) => { return {
        config: VEGA_CONFIG,
        width: VEGA_WIDTH,
        height: VEGA_HEIGHT,
        mark: 'bar',
        title: {
            text: "Distance per Week",
        },
        encoding: {
            x: { field: 'date', type: "temporal", timeUnit:"yearweek", axis: { title: "Week of Workout", format: "%Y-%m-%d"}},
            y: { aggregate: "sum", field: "total_distance", axis: { title: "Total Distance (m)", tickMinStep: 1}},
            tooltip: [
                {aggregate: "sum", field: "total_distance"},
            ]
        },
        data: { name: 'table' }, 
    }}))
}